import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, Button, message } from 'antd';
import { UPDATE_ROUTE_BASE } from '../../mutations';
import propTypes from 'prop-types';

const DeleteRouteBase = ({selectedRoutes, refetch }) => {
    const [updateRouteBase] = useMutation(UPDATE_ROUTE_BASE, {
        onCompleted: (data) => {
            message.success('ルートを削除しました。')
            refetch()
        },
        onError: (error) => {
            console.log(error);
        }
    });
    const [visible, setVisible] = useState(false);

    return <Popconfirm
        title='ルートを削除してもよろしいですか。'
        onConfirm={() => {
            selectedRoutes.forEach(route_id => {
                updateRouteBase({ 
                    variables: { 
                        input: { 
                            id: route_id,
                            isVisible: false
                        } 
                    } 
                })
                setVisible(false);
            })
        }}
        open={visible}
        okText={'削除する'}
        onCancel={() => setVisible(false)}
    >
        <Button
       disabled={selectedRoutes.length === 0}
            onClick={() => setVisible(true)}
            icon={<DeleteOutlined/>}>
            削除
        </Button>
    </Popconfirm>
        ;
};

DeleteRouteBase.propTypes = {
    refetch: propTypes.func,
    selectedRoutes: propTypes.array
};

export default DeleteRouteBase;
