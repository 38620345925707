import React, { useEffect, useState, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
    Button,
    Card,
    Space,
    Typography,
    Table,
    Progress,
    message,
    Skeleton,
    Spin
} from 'antd';
import { WarningFilled, CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { debounce } from 'lodash'; // Import debounce from lodash
import { GET_STATIONS, GET_MANUAL_FLAG_PERCENTAGE, GET_LABEL_MODELS} from '../../queries';
import { CREATE_LABEL_MODEL } from '../../mutations';
import { authenticationService } from '../../Utilities/authenticationService';
import momentify from '../../Utilities/momentify';
import moment from 'moment';

const LabelModel = () => {
    const currentUser = authenticationService.currentUserValue
    const { loading: loadingStations, data: stationsData } = useQuery(GET_STATIONS, {
        fetchPolicy: 'cache-and-network',
    });
    const { loading: loadingModels, data: labelModelData } = useQuery(GET_LABEL_MODELS, {
        fetchPolicy: 'network-only',
    });
    const { loading: loadingManual, data: manualLabelData, refetch } = useQuery(GET_MANUAL_FLAG_PERCENTAGE, {
        variables: { companyId: currentUser.company_id }, // Replace `yourCompanyId` with the actual company ID
        fetchPolicy: 'no-cache',
    });
    const [rows, setRows] = useState([]);
    const [createLabelModel] = useMutation(CREATE_LABEL_MODEL);
    const [loadingRow, setLoadingRow] = useState({});

    // Determine if a label model is available for a given station ID
    const isModelAvailableForStation = useCallback(
        (stationId) => {
            if (!labelModelData?.labelModels?.edges) return false;
            return labelModelData.labelModels.edges.some(
                (edge) => parseInt(edge.node.stationId) === parseInt(stationId)
            );
        }, [labelModelData]
    );

    // Get the updatedAt date for a given station ID
    const getUpdatedAtForStation = useCallback(
        (stationId) => {
            if (!labelModelData?.labelModels?.edges) return null;

            // Filter by stationId
            const filteredModels = labelModelData.labelModels.edges.filter(
                (edge) => parseInt(edge.node.stationId) === parseInt(stationId)
            );
    
            // Sort by updatedAt (descending)
            const sortedModels = filteredModels.sort(
                (a, b) => new Date(b.node.updatedAt) - new Date(a.node.updatedAt)
            );
    
            // Return the latest model (first in the sorted array) or null if none exist
            return sortedModels.length > 0 ? sortedModels[0].node.updatedAt : null;
        }, 
        [labelModelData]
    );

    const updateRows = useCallback(
        debounce(() => {
            if (manualLabelData && manualLabelData.manualFlagPercentage) {
                const updatedRows = manualLabelData.manualFlagPercentage.map((manualPercent) => {
                    const { stationId, manualPercentage } = manualPercent;
                    return {
                        station: stationId,
                        progress: manualPercentage,
                        updatedAt: getUpdatedAtForStation(stationId),
                        isModelAvailable: isModelAvailableForStation(stationId),
                    };
                }).sort((a, b) => b.progress - a.progress);
                setRows(updatedRows);
            } else {
                setRows([]);
            }
        }, 500), // Debounce time of 5 second
        [manualLabelData, getUpdatedAtForStation, isModelAvailableForStation]
    );

    useEffect(() => {
        if (!loadingManual) {
            updateRows();
        }
    }, [loadingManual, updateRows]);

    const handleExecuteLabelModel = async (stationId, progress) => {
        setLoadingRow((prev) => ({ ...prev, [stationId]: true }));
        try {
            await createLabelModel({
                variables: {
                    input: { stationId, progress },
                },
            });
            message.success('シミュレーションを実行しました。処理が完了するまでしばらくお待ちください。');

            // Refetch and update rows with the new data
            await refetch();
            updateRows();
        } catch (error) {
            message.error('シミュレーションの実行に失敗しました。');
            console.error(error);
        } finally {
            setLoadingRow((prev) => ({ ...prev, [stationId]: false })); // Reset loading state
        }
    };

    const columns = [
        {
            title: <Typography.Text data-testid='table-col-station' strong>営業所</Typography.Text>,
            dataIndex: 'station',
            width: '20%',
            render: (station) => {
                const stationData = stationsData?.stations?.edges?.find(
                    (edge) => parseInt(edge.node.id) === parseInt(station)
                );
                return (
                    <Typography.Text>
                        {stationData ? stationData.node.officialName : '不明な営業所'}
                    </Typography.Text>
                );
            }
        },
        {
            title: <Typography.Text data-testid="table-col-status" strong>ラベルデータ</Typography.Text>,
            width: '50%',
            render: (record) => {
                const isHighProgress = record.progress >= 50;
        
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', maxWidth: '200px' }}>
                        {/* Progress Bar */}
                        <div style={{ flexGrow: 1 }}>
                            <Progress
                                percent={record.progress}
                                steps={20}
                                status="active"
                                showInfo={false} // Disable built-in percentage display
                            />
                        </div>
                        {/* Icon */}
                        {isHighProgress ? (
                            <CheckCircleFilled style={{ color: 'green', fontSize: '16px' }} />
                        ) : (
                            <WarningFilled style={{ color: 'red', fontSize: '16px' }} />
                        )}
                        {/* Percentage Display */}
                        <Typography.Text style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
                            {record.progress}%
                        </Typography.Text>
                    </div>
                );
            },
        },
        {
            title: <Typography.Text data-tetid='table-col-command' strong>操作</Typography.Text>,
            align: 'center',
            width: '15%',
            render: (record) => {
                const isHighPercentage = record.progress > 50; // Assume 'progress' holds the manualPercentage value
                const isLoading = loadingRow[record.station]

                return (
                    <Space>
                        <Button
                            type={isHighPercentage ? 'primary' : 'default'}
                            style={{ borderRadius: "7px" }} // Rounded corners
                            onClick={() => {
                                if (isHighPercentage && !isLoading) {
                                    try {
                                        const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
                                        message.success('モデル作成・更新中');
                            
                                        // Asynchronous call
                                        handleExecuteLabelModel(record.station, record.progress);
                            
                                        // Update the rows state
                                        setRows((prevRows) =>
                                            prevRows.map((row) =>
                                                row.station === record.station
                                                    ? { ...row, updatedAt: currentDate }
                                                    : row
                                            )
                                        );
                                    } catch (error) {
                                        message.error('モデルの作成に失敗しました');
                                        console.error(error);
                                    }
                                } else if (!isHighPercentage){
                                    message.info('ラベルデータが不足しています。50%以上のデータが必要です');
                                }
                            }}
                            disabled = {isLoading}
                        >
                            {isLoading ? (
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: 'white' }} spin />} />
                            ) : (
                                record.isModelAvailable ? '更新' : '作成'
                            )}
                        </Button>
                    </Space>
                )
            }
        },
        {
            title: <Typography.Text data-testid="table-col-updated" strong>更新日</Typography.Text>,
            dataIndex: "updatedAt",
            width: '15%',
            render: (updatedAt, record) => (
                <Typography.Text>
                    {record.isModelAvailable
                        ? momentify(updatedAt) // Display the formatted date if the model is available
                        : "モデル未作成"}
                </Typography.Text>
            ),
        }
    ];

    if (loadingStations || loadingModels || loadingManual) {
        return <Skeleton active />;
    }

    return (
        <Space direction='vertical' style={{ width: '100%' }}>
            <Card bordered={false} style={{padding: '0px 24px 0px 24px'  }}>
                <Table
                    columns={columns}
                    dataSource={rows}
                    pagination={false}
                    bordered
                />
            </Card>
        </Space>
    );
};

export default LabelModel