import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
    Table,
    Select,
    TimePicker,
    Button,
    Modal,
    message,
} from 'antd';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { GET_LABELS } from '../../queries';
import { CREATE_ROUTE_BASE } from '../../mutations';
import { useLazyQuery } from '@apollo/client';
import { dowDictionary } from '../../Utilities/dayOfWeek';
import { minTruckLoadDictionary } from '../../Utilities/minTruckLoad';
import { PlusCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const AddRouteBase = ({ stationsData, refetch, scenarioId, selectedStation }) => {
    const [getLabelsData, { data: labelsData, refetch: refetchLabels }] = useLazyQuery(GET_LABELS);
    const [createRouteBase] = useMutation(CREATE_ROUTE_BASE);
    const [visible, setVisible] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const handleAddRow = () => {
        setDataSource([
            ...dataSource,
            {
                stationId: selectedStation,
                opsDow: undefined,
                labelId: undefined,
                departureDow: undefined,
                departureTime: undefined,
                arrivalDow: undefined,
                arrivalTime: undefined,
                vehicleType: undefined,
                minTruckLoad: undefined,
                parkedLoad: undefined,
            },
        ]);
    };

    const handleCopyRow = (index) => {
        const newRow = { ...dataSource[index] }; 
        setDataSource([...dataSource, newRow]); 
    };
    
    const handleDeleteRow = (index) => {
        setDataSource(dataSource.filter((_, i) => i !== index));
    };

    const handleFieldChange = (index, field, value) => {
        const newDataSource = [...dataSource];
        newDataSource[index][field] = value;
        setDataSource(newDataSource);
    };

    const handleOk = async () => {
        const results = [];
        for (const row of dataSource) {
            const res = await createRouteBase({
                variables: {
                    input: {
                        stationId: row.stationId,
                        opsDow: row.opsDow,
                        labelId: row.labelId,
                        departureDow: row.departureDow,
                        departureTime: row.departureTime,
                        arrivalDow: row.arrivalDow,
                        arrivalTime: row.arrivalTime,
                        vehicleType: row.vehicleType,
                        minTruckLoad: row.minTruckLoad,
                        parkedLoad: row.parkedLoad,
                        scenarioId,
                    },
                },
            });
            results.push(res);
        }
        if (results.length) {
            refetch();
        }
        setDataSource([]);
        setVisible(false);
        message.success('ルートを追加しました。');
    };
    useEffect(() => {
        getLabelsData({ variables: { stationId: selectedStation } });
    }, [selectedStation, getLabelsData]);

    const columns = [
        {
            title: '',
            render: (_, __, index) => (
                <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={() => handleCopyRow(index)} 
                />
            ),
        },        
        {
            title: '営業所',
            dataIndex: 'stationId',
            render: (_, record, index) => (
                <Select
                    value={record.stationId}
                    onChange={(value) => handleFieldChange(index, 'stationId', value)}
                    disabled
                >
                    {stationsData.stations.edges.map((station) => (
                        <Select.Option key={station.node.id} value={station.node.id}>
                            {station.node.officialName}
                        </Select.Option>
                    ))}
                </Select>
            ),
        },
        {
            title: '曜日',
            dataIndex: 'opsDow',
            render: (_, record, index) => (
                <Select
                    value={record.opsDow}
                    onChange={(value) => handleFieldChange(index, 'opsDow', value)}
                    placeholder="選択してください"
                >
                    {Object.entries(dowDictionary).map(([key, value]) => (
                        <Select.Option key={key} value={Number(key)}>
                            {value}
                        </Select.Option>
                    ))}
                </Select>
            ),
        },
        {
            title: 'ルート名',
            dataIndex: 'labelId',
            render: (_, record, index) => (
                <Select
                    value={record.labelId}
                    onChange={(value) => handleFieldChange(index, 'labelId', value)}
                    placeholder="選択してください"
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {labelsData?.mostFrequentlyUsedLabels.map((label) =>
                        label.enabled ? (
                            <Select.Option key={label.id} value={label.id}>
                                {label.label.join('|')}
                            </Select.Option>
                        ) : null
                    )}
                </Select>
            ),
        },
        {
            title: '出発曜日',
            dataIndex: 'departureDow',
            render: (_, record, index) => (
                <Select
                    value={record.departureDow}
                    onChange={(value) => handleFieldChange(index, 'departureDow', value)}
                    placeholder="選択してください"
                >
                    {Object.entries(dowDictionary).map(([key, value]) => (
                        <Select.Option key={key} value={Number(key)}>
                            {value}
                        </Select.Option>
                    ))}
                </Select>
            ),
        },
        {
            title: '出発時刻',
            dataIndex: 'departureTime',
            render: (_, record, index) => (
                <TimePicker
                    value={record.departureTime}
                    showTime={{ format: 'HH:mm' }}
                    locale={locale}
                    onChange={(value) => handleFieldChange(index, 'departureTime', value)}
                    placeholder="時刻を選択"
                />
            ),
        },
        {
            title: '到着曜日',
            dataIndex: 'arrivalDow',
            render: (_, record, index) => (
                <Select
                    value={record.arrivalDow}
                    onChange={(value) => handleFieldChange(index, 'arrivalDow', value)}
                    placeholder="選択してください"
                >
                    {Object.entries(dowDictionary).map(([key, value]) => (
                        <Select.Option key={key} value={Number(key)}>
                            {value}
                        </Select.Option>
                    ))}
                </Select>
            ),
        },
        {
            title: '到着時刻',
            dataIndex: 'arrivalTime',
            render: (_, record, index) => (
                <TimePicker
                    value={record.arrivalTime}
                    showTime={{ format: 'HH:mm' }}
                    locale={locale}
                    onChange={(value) => handleFieldChange(index, 'arrivalTime', value)}
                    placeholder="時刻を選択"
                />
            ),
        },
        {
            title: '車種',
            dataIndex: 'vehicleType',
            render: (_, record, index) => (
                <Select
                    value={record.vehicleType}
                    onChange={(value) => handleFieldChange(index, 'vehicleType', value)}
                    placeholder="選択してください"
                >
                    {['冷凍車', '冷蔵車'].map((type) => (
                        <Select.Option key={type} value={type}>
                            {type}
                        </Select.Option>
                    ))}
                </Select>
            ),
        },
        {
            title: '重量（キロ）',
            dataIndex: 'minTruckLoad',
            render: (_, record, index) => (
                <Select
                    value={record.minTruckLoad}
                    onChange={(value) => handleFieldChange(index, 'minTruckLoad', value)}
                    placeholder="選択してください"
                >
                    {Object.entries(minTruckLoadDictionary).map(([label, value]) => (
                        <Select.Option key={value} value={Number(value)}>
                            {label}
                        </Select.Option>
                    ))}
                </Select>
            ),
        },
        {
            title: '荷有り',
            dataIndex: 'parkedLoad',
            render: (_, record, index) => (
                <Select
                    value={record.parkedLoad}
                    onChange={(value) => handleFieldChange(index, 'parkedLoad', value)}
                    placeholder="選択してください"
                >
                    {['空', '荷あり'].map((type) => (
                        <Select.Option key={type} value={type}>
                            {type}
                        </Select.Option>
                    ))}
                </Select>
            ),
        },
        {
            title: '',
            dataIndex: 'actions',
            render: (_, __, index) => (
                <Button
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteRow(index)}
                />
            ),
        },
    ];

    return (
        <>
            <Button
                onClick={() => {setVisible(true); refetchLabels(); handleAddRow()}}
                icon={<PlusCircleOutlined />}
            >
                追加
            </Button>
            <Modal
                title="固定ルートの追加"
                open={visible}
                onCancel={() => setVisible(false)}
                footer={[
                    <Button key="cancel" onClick={() => setVisible(false)}>
                        キャンセル
                    </Button>,
                    <Button
                        key="ok"
                        type="primary"
                        onClick={handleOk}
                        disabled={
                            dataSource.length === 0 || 
                            !dataSource.every(
                                (row) =>
                                    row.opsDow !== undefined &&
                                    row.labelId !== undefined &&
                                    row.departureTime !== undefined &&
                                    row.arrivalTime !== undefined &&
                                    row.vehicleType !== undefined &&
                                    row.minTruckLoad !== undefined &&
                                    row.parkedLoad !== undefined
                            )
                        }
                    >
                        追加
                    </Button>,
                ]}
                width='90%'
            >
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    rowKey={(record, index) => index}
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                />
            </Modal>
        </>
    );
};

export default AddRouteBase;
